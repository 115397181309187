import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"
import {globalHistory} from "@reach/router"
import Components from "../components/components.js"
import {Vimeo, Calendly} from "../components/Plugin"
import Footer from "../components/Footer"
import ScrollHint from "../components/UILayout/ScrollHint"
import {VideoPlayerContext, PricingContext} from "@utils"
import {backgrounds} from "@constants/colors"

import "@styles/layout.css"
import "@fonts/proxima-nova-web-fonts/fonts.css"
import "@styles/bootstrap-grid-utils.css"
class StoryBlokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null
		}

		return StoryBlokEntry.prepareStory(props)
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story)
		story.content = JSON.parse(story.content)
		return {
			story
		}
	}

	constructor(props) {
		super(props)
		this.state = {
			pricing: {essentialeap: 0, attentiveCare: 0, attentiveCarePlus: 0},
			pricingCardRef: null,
			showScrollHint: true,
			playVideo: false,
			url: "https://player.vimeo.com/video/219316897",
			...StoryBlokEntry.prepareStory(props)
		}
	}

	componentDidMount() {
		// when privacy-us page is transioned, and not opened directly
		this.listener = globalHistory.listen(({action, location}) => {
			setTimeout(() => {
				if (location.pathname === "/privacy-us/" && location.hash === "#california") {
					const element = [...document.getElementsByTagName("p")].find(el =>
						el.innerText.includes("Your California Privacy Rights")
					)
					window.scrollTo(0, element?.offsetTop + 250 || 10000)
				}
			}, 1200)
		})
	}

	componentWillUnmount() {
		this.listener()
	}

	render() {
		let content = {
			...this.state.story.content,
			// Remove `header` from component list
			title: this.state.story.name,
			body: this.state.story.content.body.filter(
				item => item.component !== "header" && item.component !== "FOOTER"
			)
		}

		const lastSection = content && content.body && content.body[content.body.length - 1]
		const footerBackgroundColor =
			lastSection && lastSection.backgroundColor === "#ffffff" ? backgrounds.fadedPurple : backgrounds.white

		return (
			<PageTransition
			// defaultStyle={{
			//   transition: 'left 500ms cubic-bezier(0.47, 0, 0.75, 0.72)',
			//   left: '100%',
			//   position: 'absolute',
			//   width: '100%'
			// }}
			// transitionStyles={{
			//   entering: { left: '0%' },
			//   entered: { left: '0%' },
			//   exiting: { left: '100%' }
			// }}
			// transitionTime={500}
			>
				<PricingContext.Provider
					value={{
						pricing: this.state.pricing,
						setPricing: pricing => this.setState({pricing}),
						pricingCardRef: this.state.pricingCardRef,
						setPricingCardRef: pricingCardRef => this.setState({pricingCardRef})
					}}>
					<VideoPlayerContext.Provider
						value={{
							playVideo: this.state.playVideo,
							togglePlayVideo: () =>
								this.setState(prevState => ({
									playVideo: !prevState.playVideo
								})),
							url: this.state.url,
							setURL: url => this.setState({url})
						}}>
						<ScrollHint isScrollHintEnabled={this.state.story.content.showScrollHint} />
						{React.createElement(Components(content.component), {
							key: content._uid,
							blok: content,
							...content
						})}
						<Footer backgroundColor={footerBackgroundColor} />
						<Vimeo />
						<Calendly />
					</VideoPlayerContext.Provider>
				</PricingContext.Provider>
			</PageTransition>
		)
	}
}

export default StoryBlokEntry
