import React, {useEffect, useState} from "react"
import Styled from "styled-components"
import {LinkButton} from "@styles/buttons"

const FixedContainer = Styled.div`
  position: fixed;
  left: 50%;
  bottom: 5%;
	z-index: 100;
	display: ${props => (props.showHint ? "block" : "none")};
`

export default function ScrollHint({isScrollHintEnabled}) {
	const [showScrollHint, setShowScrollHint] = useState(true)
	const [needHint, setNeedHint] = useState(false)
	// Manage scroll
	useEffect(() => {
		const handleOnScroll = () => {
			if (window.scrollY > 200 && showScrollHint) {
				setShowScrollHint(false)
			}
			if (window.scrollY < 200 && !showScrollHint) {
				setShowScrollHint(true)
			}
		}

		window.addEventListener("scroll", handleOnScroll)

		return () => {
			window.removeEventListener("scroll", handleOnScroll)
		}
	}, [showScrollHint])
	// Manage resize
	useEffect(() => {
		const handleWindowResize = () => {
			setNeedHint(window.innerHeight < 900)
		}
		// Set value of `mount`
		handleWindowResize()

		window.addEventListener("resize", handleWindowResize)

		return () => {
			window.removeEventListener("resize", handleWindowResize)
		}
	}, [])

	return (
		<FixedContainer showHint={needHint && isScrollHintEnabled && showScrollHint}>
			<LinkButton
				className='bounce'
				onClick={() =>
					window.scrollTo({
						top: 900,
						left: 0,
						behavior: "smooth"
					})
				}>
				<img
					src={require("../../images/icons/dropdown.svg")}
					alt='scroll down to see more content'
					width='32'
					height='32'
				/>
			</LinkButton>
		</FixedContainer>
	)
}
